import * as React from "react"
import Layout from "../../components/layout"

import ColumnWithIcon from "../../modules/columnWithIcon";
import Image from "../../modules/imageComponent";
import Paginator from "../../modules/paginator";

import anim1 from '../../images/animations/icon_5/animation'
import anim2 from '../../images/animations/icon_6/animation'
import anim3 from '../../images/animations/icon_12/animation'
import anim4 from '../../images/animations/icon_8/animation'

import Lottie from "react-lottie-player";
import mainAnim from "../../images/animations/main_icon_3/animation";

const StakePage = () => {
    return (
        <Layout className={'technology'} title={'Stake'}>
            <section>
                <div className={'container'}>

                    <div className={'row pb-0 mb-0 align-items-center'}>
                        <div className={'col col-12 col-md-4 col-lg-3 col-md-3'}>
                        </div>
                        <div className={'col col-12 col-md-8 col-lg-9'}>
                            <Paginator current={2} prev={1} next={3}/>
                        </div>
                    </div>
                    <div className={'row pb-lg-5 align-items-center'}>
                        <div className={'col col-12 col-md-4 col-lg-3 py-5 py-md-0 pe-md-5 hero-image stake'}>
                            <Lottie animationData={mainAnim} play loop={false}/>
                        </div>
                        <div className={'col col-12 col-md-5 align-top'}>
                            <h1>Stake</h1>
                            <div className={'subtitle'}>
                                The world's first shielded proof-of-stake network, with privacy for delegators and accountability for validators.
                            </div>
                        </div>
                    </div>

                    <div className={'row pt-4'}>
                        <div className={'col col-12 col-md-6 col-lg-3 py-4 py-md-5 px-lg-0'}>
                            <ColumnWithIcon animation={anim1} icon={'technology/icon3.svg'} title={'Staking Token'} text={'The PEN staking token secures the Penumbra network and allows token holders to participate in project governance through on-chain voting. Penumbra uses a new staking design that provides privacy for delegators and accountability for validators.'}/>
                        </div>
                        <div className={'col col-12 col-md-6 col-lg-3 py-4 py-md-5 px-lg-0'}>
                            <ColumnWithIcon animation={anim2} icon={'technology/icon4.svg'} title={'Native Delegation Tokens'} text={'For each validator, Penumbra provides a native delegation token called dPEN, representing a share of the delegation pool staked with that validator. These tokens are recorded in the shielded pool and can be transferred or traded like any other asset.'}/>
                        </div>
                        <div className={'col col-12 col-md-6 col-lg-3 py-4 py-md-5 px-lg-0'}>
                            <ColumnWithIcon animation={anim3} icon={'technology/icon1.svg'} title={'Private Delegation'} text={'Stakeholders can privately delegate to a validator by exchanging staking tokens for the validator’s delegation token. Instead of issuing rewards to delegators, rewards accrue to the delegation pool and are realized only when a delegator withdraws their delegation.'}/>
                        </div>
                        <div className={'col col-12 col-md-6 col-lg-3 py-4 py-md-5 px-lg-0'}>
                            <ColumnWithIcon animation={anim4} icon={'technology/icon5.svg'} title={'On-chain Governance'} text={'Stakeholders decide the future of the network by voting on governance proposals, ensuring that the project’s direction is not controlled by any one entity.'}/>
                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    )
}

export default StakePage
